import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  useToast,
  VStack,
  HStack,
  Spinner,
} from "@chakra-ui/react";
import { useCookies } from "react-cookie";
import axios from "axios";

const AddPayment = ({ onClose, selectedPayment, refreshData }) => {
  const [formData, setFormData] = useState({
    paymentType: "",
    partyId: "",
    amount: "",
    date: "",
    remarks: "",
  });
  const toast = useToast();
  const [cookies] = useCookies();

  const [partyData, setPartyData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchPartyData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}party/`,
          {
            headers: { Authorization: `Bearer ${cookies?.token}` },
          }
        );
        setPartyData(response?.data?.data);
      } catch (error) {
        console.log(error);

        toast({
          title: "Error",
          description: "Failed to fetch party names.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchPartyData();

    if (selectedPayment) {
      // Pre-populate the form if there's a selected payment
      setFormData({
        paymentType: selectedPayment.paymentType,
        partyId: selectedPayment.partyId?._id,
        amount: selectedPayment.amount,
        date: new Date(selectedPayment.date).toISOString().split('T')[0], // Convert date to 'YYYY-MM-DD' format
        remarks: selectedPayment.remarks || "",
      });
    }
  }, [cookies?.token, selectedPayment]); // Re-fetch data and update form if selectedPayment changes

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !formData.paymentType ||
      !formData.partyId ||
      !formData.amount ||
      !formData.date
    ) {
      toast({
        title: "Error",
        description: "Please fill in all required fields.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      let response;
      if (selectedPayment) {
      
        response = await axios.patch(
          `${process.env.REACT_APP_BACKEND_URL}payment/update/${selectedPayment._id}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${cookies?.token}`,
            },
          }
        );
      } else {
      
        response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}payment/new`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${cookies?.token}`,
            },
          }
        );
      }

      console.log(response)
      toast({
        title: "Success",
        description: `${response?.message ? response?.message : 'Payment added successfully!'}`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      // Reset form after submission
      setFormData({
        paymentType: "",
        partyId: "",
        amount: "",
        date: "",
        remarks: "",
      });
      onClose();
      refreshData();
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to submit payment. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <VStack spacing={4} align="stretch" p={6}>
      <HStack justify="space-between" align="center">
        <h2 className="text-2xl font-bold">{selectedPayment ? "Edit Payment" : "Add Payment"}</h2>
      </HStack>

      <form onSubmit={handleSubmit}>
        <VStack spacing={4} align="stretch">
          <FormControl id="paymentType" isRequired>
            <FormLabel>Payment Type</FormLabel>
            <Select
              name="paymentType"
              value={formData.paymentType}
              onChange={handleChange}
              placeholder="Select Payment Type"
            >
              <option value="debit">Debit</option>
              <option value="credit">Credit</option>
            </Select>
          </FormControl>

          <FormControl id="partyId" isRequired>
            <FormLabel>Party Name</FormLabel>
            {loading ? (
              <Spinner size="sm" />
            ) : (
              <Select
                name="partyId"
                value={formData.partyId}
                onChange={handleChange}
                placeholder="Select Party Name"
              >
                {partyData.map((party) => (
                  <option key={party._id} value={party._id}>
                    {party.name}
                  </option>
                ))}
              </Select>
            )}
          </FormControl>

          <FormControl id="amount" isRequired>
            <FormLabel>Amount</FormLabel>
            <Input
              type="number"
              name="amount"
              value={formData.amount}
              onChange={handleChange}
              placeholder="Enter Amount"
            />
          </FormControl>

          <FormControl id="date" isRequired>
            <FormLabel>Date</FormLabel>
            <Input
              type="date"
              name="date"
              value={formData.date}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl id="remarks">
            <FormLabel>Remarks/Purpose</FormLabel>
            <Textarea
              name="remarks"
              value={formData.remarks}
              onChange={handleChange}
              placeholder="Enter Remarks/Purpose"
            />
          </FormControl>

          <Button colorScheme="blue" type="submit">
            {selectedPayment ? "Update Payment" : "Submit Payment"}
          </Button>
        </VStack>
      </form>
    </VStack>
  );
};

export default AddPayment;
