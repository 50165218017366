import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Button,
  useToast,
  ModalCloseButton,
} from "@chakra-ui/react";
import axios from "axios";
import { useCookies } from "react-cookie";

const UpdateParty = ({ isOpen, onClose, party, refreshData }) => {
  const [formData, setFormData] = useState({
    name: "",
    address: "",
    gstNo: "",
  });
  const toast = useToast();
  const [cookies] = useCookies();

  useEffect(() => {
    if (party) {
      setFormData({
        name: party.name || "",
        address: party.address || "",
        gstNo: party.gstNo || "",
      });
    }
  }, [party]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}party/update/${party._id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${cookies?.token}`,
          },
        }
      );

      toast({
        title: "Success",
        description: response?.data?.message || "Party updated successfully!",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      onClose(); 
      refreshData();
    } catch (error) {
        console.log(error);
        
      toast({
        title: "Error",
        description: "Failed to update party.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Party</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Name</FormLabel>
            <Input name="name" value={formData.name} onChange={handleChange} />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>Address</FormLabel>
            <Input name="address" value={formData.address} onChange={handleChange} />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>GST No</FormLabel>
            <Input name="gstNo" value={formData.gstNo} onChange={handleChange} />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={handleSubmit}>Save Changes</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UpdateParty;
