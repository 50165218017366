import { Button, Box, Text, VStack, Grid, GridItem } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { CiReceipt } from "react-icons/ci";
import { TbReceiptRupee } from "react-icons/tb";
import { useToast } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { LiaFileInvoiceSolid } from "react-icons/lia";
import moment from "moment";
import Loader from "../components/Loader";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useCookies } from "react-cookie";

const BillDetails = () => {
  const toast = useToast();
  const [billedToNameAddress, setBilledToNameAddress] = useState("");
  const [challanNo, setChallanNo] = useState("");
  const [billNo, setBillNo] = useState("");
  const [biltyNo, setBiltyNo] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [lorryNo, setLorryNo] = useState("");
  const [weight, setWeight] = useState();
  const [total, setTotal] = useState();
  const [advancePaid, setAdvancePaid] = useState();
  const [balancePaid, setBalancePaid] = useState();
  const [driverName, setDriverName] = useState("");
  const [driverPhone, setDriverPhone] = useState("");
  const [date, setDate] = useState(new Date().toISOString().substring(0, 10));
  const [contents, setContents] = useState("");
  const [bookingStation, setBookingStation] = useState("");

  const [priceType, setPriceType] = useState("Fixed");
  const [priceTotal, setPriceTotal] = useState();
  const [gstNo, setGstNo] = useState();
  const [stCh, setStCh] = useState();
  const [delCh, setDelCh] = useState();
  const [description, setDescription] = useState();

  const [fetchingBill, setFetchingBill] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const [cookies] = useCookies();

  const fetchBillDetails = async (billId) => {
    try {
      setFetchingBill(true);
      const baseUrl = process.env.REACT_APP_BACKEND_URL;
      const response = await fetch(baseUrl + `bill/get?billId=${billId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${cookies?.token}`,
        },
      });
      const data = await response.json();
      if (!data.success) {
        throw new Error(data.message);
      }
      setBilledToNameAddress(data?.consignee_name_address || "N/A");
      setBillNo(data?.bill_no || "N/A");
      setDate(data?.date || "N/A");
      setChallanNo(data?.challan_no || "N/A");
      setLorryNo(data?.truck_no || "N/A");
      setWeight(data?.weight || "N/A");
      setBalancePaid(data?.balance || "N/A");
      setAdvancePaid(data?.advance || "N/A");
      setTotal(data?.total || "N/A");
      setGstNo(data?.gst_no || "N/A");
      setDelCh(data?.del_ch || "N/A");
      setStCh(data?.st_ch || "N/A");
      setDescription(data?.description || "N/A");
      setPriceType(data?.price_type || "N/A");
      setPriceTotal(data?.total || "N/A");
      setFrom(data?.from || "N/A");
      setTo(data?.to || "N/A");
      setBiltyNo(data?.bilty_no || "N/A");
      setContents(data?.contents || "N/A");
      setBookingStation(data?.consignee_address || "N/A");
    } catch (err) {
      toast({
        position: "top",
        status: "error",
        description: err.message,
      });
    } finally {
      setFetchingBill(false);
    }
  };

  useEffect(() => {
    if (location?.state?.billId) {
      fetchBillDetails(location?.state?.billId);
    }
  }, []);

  return (
    <Box py={5} px={6} minHeight="100vh" bg="gray.50">
      <Box mb={6} display="flex" alignItems="center" gap={3}>
        <IoMdArrowRoundBack
          cursor="pointer"
          color="gray.600"
          fontSize="24px"
          onClick={() => navigate("/dashboard/all-bill")}
        />
        <Text fontSize="3xl" fontWeight="bold" color="gray.800">Bill Details</Text>
      </Box>

      {fetchingBill && <Loader />}

      {!fetchingBill && (
        <VStack spacing={6}>
          <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)", lg: "repeat(3, 1fr)" }} gap={6}>
            <InfoCard label="Bill No." value={billNo} />
            <InfoCard label="Challan No." value={challanNo} />
            <InfoCard label="Bilty No." value={biltyNo} />
            <InfoCard label="From" value={from} />
            <InfoCard label="To" value={to} />
            <InfoCard label="Lorry No." value={lorryNo} />
          </Grid>

          <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)", lg: "repeat(3, 1fr)" }} gap={6}>
            <InfoCard label="Date" value={moment(date).format("DD-MM-YYYY")} />
            <InfoCard label="Contents" value={contents} />
            <InfoCard label="Booking Station" value={bookingStation} />
            <InfoCard label="Weight (In Tonne)" value={weight} />
            <InfoCard label="Billed To" value={billedToNameAddress} />
          </Grid>

          <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)", lg: "repeat(3, 1fr)" }} gap={6}>
            <InfoCard label="Description" value={description} />
            <InfoCard label="Price Type" value={priceType} />
            <InfoCard label="Total" value={priceTotal} />
            <InfoCard label="GST No." value={gstNo} />
            <InfoCard label="St. Ch." value={stCh} />
            <InfoCard label="Del. Ch." value={delCh} />
          </Grid>
        </VStack>
      )}
    </Box>
  );
};

const InfoCard = ({ label, value }) => (
  <Box bg="white" p={5} borderRadius="md" shadow="sm" borderWidth="1px">
    <Text fontSize="sm" color="gray.500" fontWeight="medium">{label}</Text>
    <Text fontSize="lg" color="gray.700" fontWeight="semibold">{value}</Text>
  </Box>
);

export default BillDetails;
