import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import AllBilty from "./pages/AllBilty";
import CreateBilty from "./pages/CreateBilty";
import CreateChallan from "./pages/CreateChallan";
import AllChallan from "./pages/AllChallan";
import EditBilty from "./pages/EditBilty";
import BiltyDetails from "./pages/BiltyDetails";
import ChallanDetails from "./pages/ChallanDetails";
import EditChallan from "./pages/EditChallan";
import AllBill from "./pages/AllBill";
import CreateBill from "./pages/CreateBill";
import Login from "./pages/Login";
import BillDetails from "./pages/BillDetails";
import EditBill from "./pages/EditBill";
import CreateParty from "./pages/CreateParty";
import Payment from "./pages/Payment";
import AllParty from "./pages/AllParty";
import Overview from "./pages/Overview";

function App() {
  return (
    // <div className="px-5 py-5">
    <div className="">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Home />}>
            <Route index element={<Overview />} />
            <Route path="all-bilty" element={<AllBilty />} />
            <Route path="all-challan" element={<AllChallan />} />
            <Route path="all-bill" element={<AllBill />} />
            <Route path="create-bilty" element={<CreateBilty />} />
            <Route path="create-challan" element={<CreateChallan />} />
            <Route path="create-bill" element={<CreateBill />} />
            <Route path="edit-bilty" element={<EditBilty />} />
            <Route path="edit-bill" element={<EditBill />} />
            <Route path="edit-challan" element={<EditChallan />} />
            <Route path="bilty-details" element={<BiltyDetails />} />
            <Route path="challan-details" element={<ChallanDetails />} />
            <Route path="bill-details" element={<BillDetails />} />
            <Route path="create-party" element={<CreateParty />} />
            <Route path="payment" element={<Payment />} />
            <Route path="party" element={<AllParty />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
