import { Button } from "@chakra-ui/react";
import { CiReceipt } from "react-icons/ci";
import { TbReceiptRupee } from "react-icons/tb";
import { LiaFileInvoiceSolid } from "react-icons/lia";
import Logo from "../assets/logo/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";
import { RiLogoutBoxLine } from "react-icons/ri";
import { useCookies } from "react-cookie";
import { useToast } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { userNotExists } from "../redux/reducers/authSlice";
import { FaRegUser } from "react-icons/fa";

const Header = ({ setOpenMobileNavigation }) => {
  const [cookies, _, removeCookie] = useCookies();
  const navigate = useNavigate();
  const toast = useToast();
  const dispatch = useDispatch();
  const {isSuper} = useSelector(state => state.auth);

  const logoutHandler = ()=>{
    if(cookies?.token){
      removeCookie('token');
      dispatch(userNotExists());
      toast({
        status: 'success',
        description: 'Logged out successfully',
        position: 'top'
      });
      navigate('/');
    }
  }

  return (
    <nav className="flex items-center justify-between py-2 px-3">
      <div>
        <img className="w-[9rem] object-cover" src={Logo} alt="logo" />
      </div>

      <div className="hidden lg:flex lg:items-center lg:gap-2">
        <Link to="create-bilty">
          <Button rightIcon={<CiReceipt size={25} />} colorScheme="blue">
            Create Bilty
          </Button>
        </Link>
        <Link to="create-challan">
          <Button rightIcon={<TbReceiptRupee size={25} />} colorScheme="blue">
            Create Challan
          </Button>
        </Link>
        {isSuper && <Link to="create-bill">
          <Button
            rightIcon={<LiaFileInvoiceSolid size={25} />}
            colorScheme="blue"
          >
            Create Bill
          </Button>
        </Link>}
        {isSuper && <Link to="create-party">
          <Button
            rightIcon={<FaRegUser  size={25} />}
            colorScheme="blue"
          >
            Create Party
          </Button>
        </Link>}
        <div>
          <Button
            onClick={logoutHandler}
            colorScheme="blue"
            rightIcon={<RiLogoutBoxLine />}
          >
            Logout
          </Button>
        </div>
      </div>

      <div
        onClick={() => setOpenMobileNavigation(true)}
        className="block lg:hidden hover:scale-105 pr-4"
      >
        <RxHamburgerMenu size={25} />
      </div>
    </nav>
  );
};

export default Header;
