import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  VStack,
  HStack,
  Text,
  Input,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import { MdDelete, MdModeEdit } from "react-icons/md";
import axios from "axios";
import { useCookies } from "react-cookie";
import UpdateParty from "./UpdateParty";

const AllParty = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const toast = useToast();
  const [searchName, setSearchName] = useState("");
  const [searchDate, setSearchDate] = useState("");
  const [cookies] = useCookies();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedParty, setSelectedParty] = useState(null);

  const fetchParty = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}party/`,
        {
          headers: {
            Authorization: `Bearer ${cookies?.token}`,
          },
        }
      );
      setData(response?.data?.data);
      setFilteredData(response?.data?.data);
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to fetch Party data :(",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchParty();
  }, []);

  // 🔍 Filter Function
  useEffect(() => {
    let filtered = data;

    if (searchName) {
      filtered = filtered.filter((party) =>
        party.name.toLowerCase().includes(searchName.toLowerCase())
      );
    }

    if (searchDate) {
      filtered = filtered.filter(
        (party) =>
          new Date(party.createdAt).toISOString().split("T")[0] === searchDate
      );
    }

    setFilteredData(filtered);
  }, [searchName, searchDate, data]);

 
  const handleEdit = (party) => {
    setSelectedParty(party); 
    onOpen(); 
  };

  return (
    <VStack spacing={4} align="stretch" className="w-full overflow-hidden p-6">
      <HStack className="flex items-center justify-between">
        <Text className="text-2xl p-3 font-bold">All Party</Text>
      </HStack>

      {/* Filters */}
      <HStack spacing={4}>
        <Input
          type="text"
          placeholder="Search by Name..."
          value={searchName}
          onChange={(e) => setSearchName(e.target.value)}
        />
        <Input
          type="date"
          value={searchDate}
          onChange={(e) => setSearchDate(e.target.value)}
        />
      </HStack>

      <div style={{ width: "100%", overflowX: "auto", marginTop: "20px" }}>
        <Table variant="simple" size="sm" style={{ minWidth: "900px" }}>
          <Thead>
            <Tr className="bg-teal-600 p-2">
              <Th>
                <Text className="text-white py-2">S.No.</Text>
              </Th>
              <Th>
                <Text className="text-white py-2">Date</Text>
              </Th>
              <Th>
                <Text className="text-white py-2">Name</Text>
              </Th>
              <Th>
                <Text className="text-white py-2">Address</Text>
              </Th>
              <Th>
                <Text className="text-white py-2">GST No</Text>
              </Th>
              <Th>
                <Text className="text-white py-2">Actions</Text>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredData?.map((row, index) => (
              <Tr key={row?._id}>
                <Td>{index + 1}</Td>
                <Td>{new Date(row?.createdAt).toLocaleDateString()}</Td>
                <Td>{row?.name}</Td>
                <Td>{row?.address}</Td>
                <Td>{row?.gstNo}</Td>
                <Td>
                  <HStack spacing={2}>
                    <Button
                      colorScheme="orange"
                      size="sm"
                      onClick={() => handleEdit(row)}
                    >
                      <MdModeEdit />
                    </Button>                    
                  </HStack>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </div>

      {/* Edit Party Modal */}
      {selectedParty && (
        <UpdateParty
          isOpen={isOpen}
          onClose={onClose}
          party={selectedParty}
          refreshData={fetchParty}
        />
      )}
    </VStack>
  );
};

export default AllParty;
