import moment from "moment";
import { usePagination, useSortBy, useTable } from "react-table";
import { MdModeEdit } from "react-icons/md";
import { IoMdEye } from "react-icons/io";
import { IoMdDownload } from "react-icons/io";
import { Badge, Box, Divider, HStack, Text, VStack } from "@chakra-ui/react";

const Table = (props) => {
  const {
    columns = [],
    data = [],
    fetchDetails = () => {},
    editHandler = () => {},
    downloadHandler = () => {},
    viewHandler = () => {},
  } = props;

  console.log(props);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    state: { pageIndex },
    pageCount,
  } = useTable({ columns, data }, useSortBy, usePagination);

  return (
    <div>
      <div className="w-full">
        {page.map((row) => {
          prepareRow(row);
          return (
            <div
              key={row.id}
              className="border rounded-lg p-4 shadow relative hover:shadow-lg transition duration-200 mb-3"
              onClick={() =>
                fetchDetails !== undefined && fetchDetails(row.cells[0].value)
              }
            >
              <Box
                className="bg-blue-200"
                position="absolute"
                top={0}
                left={0}
                h="100%"
                w={2}
                bg="blue.200"
                borderRadius="md"
              />

              {/* First HStack for some fields */}
              <HStack spacing={4}  className="flex items-start justify-between">
                {row.cells.map((cell, index) => {
                  if (index < row.cells.length / 3) {
                    return (
                      <Text key={cell.column.id} >
                        <strong>{cell.column.Header}: </strong>
                        {cell.column.id !== "date" ? (
                          <span>
                            {cell?.value?.toString()?.substr(0, 50)}
                            {cell?.value?.length > 50 && "..."}
                          </span>
                        ) : (
                          moment(row.original.date).format("DD-MM-YYYY")
                        )}
                      </Text>
                    );
                  }
                  return null;
                })}
              </HStack>

              <Divider my={2} />

              {/* Second HStack for other fields */}
              <HStack spacing={4}  className="flex items-start justify-between mb-2">
                {row.cells.map((cell, index) => {
                  if (index >= row.cells.length / 3) {
                    return (
                      <Text key={cell.column.id}>
                        <strong>{cell.column.Header}: </strong>
                        {cell.column.id !== "date" ? (
                          <sapn>
                            {cell?.value?.toString()?.substr(0, 50)}
                            {cell?.value?.length > 50 && "..."}
                          </sapn>
                        ) : (
                          moment(row.original.date).format("DD-MM-YYYY")
                        )}
                      </Text>
                    );
                  }
                  return null;
                })}
              </HStack>

              <Divider />
              <HStack
                className="flex gap-2 items-end  justify-end mt-2"
                align="end"
              >
                <button
                  onClick={() => viewHandler(row.original._id)}
                  className="bg-green-500 text-white rounded-full p-2 hover:scale-110"
                >
                  <IoMdEye />
                </button>
                <button
                  onClick={() => editHandler(row.original._id)}
                  className="bg-blue-500 text-white rounded-full p-2 hover:scale-110"
                >
                  <MdModeEdit />
                </button>
                <button
                  onClick={() => downloadHandler(row.original._id)}
                  className="bg-orange-500 text-white rounded-full p-2 hover:scale-110"
                >
                  <IoMdDownload />
                </button>
              </HStack>
            </div>
          );
        })}
      </div>

      <div className="flex justify-center items-center my-4">
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded-lg mr-2 disabled:bg-gray-300 disabled:cursor-not-allowed"
          disabled={!canPreviousPage}
          onClick={previousPage}
        >
          Prev
        </button>
        <span>
          {pageIndex + 1} of {pageCount}
        </span>
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded-lg ml-2 disabled:bg-gray-300 disabled:cursor-not-allowed"
          disabled={!canNextPage}
          onClick={nextPage}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Table;
