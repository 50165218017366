import React, { useEffect, useState } from "react";
import {
  Box,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  Heading,
  Select,
  VStack,
  useToast,
  Img,
  Input,
  HStack,
  Divider,
} from "@chakra-ui/react";
import axios from "axios";
import { useCookies } from "react-cookie";
import { NavLink } from "react-router-dom";

const Overview = () => {
  const [stats, setStats] = useState({
    totalBilty: 0,
    totalBill: 0,
    totalChallan: 0,
    totalPayment: 0,
    totalParty: 0,
  });

  const [paymentFilter, setPaymentFilter] = useState("all");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [cookies] = useCookies();
  const toast = useToast();

  const fetchStats = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}dashboard/overview`,
        {
          params: {
            paymentType: paymentFilter,
            fromDate: fromDate || undefined,
            toDate: toDate || undefined,
          },
          headers: {
            Authorization: `Bearer ${cookies?.token}`,
          },
        }
      );
      console.log(response);

      setStats(response?.data);
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to fetch dashboard data.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchStats();
  }, [paymentFilter, fromDate, toDate]);

  return (
    <VStack spacing={6} p={6} align="stretch">
      <Heading size="lg">Dashboard Overview</Heading>

      {/* Filters */}
      <HStack spacing={4} className="w-full flex">
        <Select
          value={paymentFilter}
          onChange={(e) => setPaymentFilter(e.target.value)}
        >
          <option value="all">All Payments</option>
          <option value="debit">Debit</option>
          <option value="credit">Credit</option>
        </Select>

        <Input
          type="date"
          value={fromDate}
          onChange={(e) => setFromDate(e.target.value)}
          placeholder="From Date"
        />
        <Input
          type="date"
          value={toDate}
          onChange={(e) => setToDate(e.target.value)}
          placeholder="To Date"
        />
      </HStack>
      <Divider />

      {/* Data Cards */}
      <SimpleGrid columns={[1, 2, 3]} spacing={6} alignContent="center">
        {[
          {
            label: "Total Bilty",
            value: stats.totalBilty,
            path: "/bilty.svg",
            link: "/dashboard/all-bilty",
          },
          {
            label: "Total Bill",
            value: stats.totalBills,
            path: "/bill.svg",
            link: "/dashboard/all-bill",
          },
          {
            label: "Total Challan",
            value: stats.totalChallan,
            path: "/challan.svg",
            link: "/dashboard/all-challan",
          },
          {
            label: "Total Payment",
            value: stats.totalPayments,
            path: "/payment.svg",
            link: "/dashboard/payment",
          },
          {
            label: "Total Debit & Credit",
            value: `₹${stats.totalDebit} / ₹${stats.totalCredit}`,
            path: "/wallet.svg",
            link: "/dashboard/payment",
          },
          {
            label: "Total Party",
            value: stats.totalParty,
            path: "/party.svg",
            link: "/dashboard/party",
          },
        ].map((item, index) => (
          <NavLink to={item.link}>
            <Box
              key={index}
              p={6}
              shadow="md"
              borderWidth="1px"
              borderRadius="lg"
              textAlign="center"
              className="flex items-center justify-center hover:scale-105 transition-all ease-linear"
            >
              <VStack spacing={3}>
                <Img src={item.path} className="w-20" />
                <Stat>
                  <StatLabel>{item.label}</StatLabel>
                  <StatNumber fontSize="2xl">{item.value}</StatNumber>
                </Stat>
              </VStack>
            </Box>
          </NavLink>
        ))}
      </SimpleGrid>
    </VStack>
  );
};

export default Overview;
