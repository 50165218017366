import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { LiaFileInvoiceSolid } from "react-icons/lia";
import { useToast } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useCookies } from "react-cookie";

const EditBill = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const [cookies] = useCookies();
  const [billData, setBillData] = useState({});
  const [billId, setBillId] = useState();
  const [fetchingBill, setFetchingBill] = useState(false);
  const [updatingBill, setUpdatingBill] = useState(false);

  // Fetch bill details
  const fetchBillDetails = async (billId) => {
    try {
      setFetchingBill(true);
      const baseUrl = process.env.REACT_APP_BACKEND_URL;
      const response = await fetch(`${baseUrl}bill/get?billId=${billId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${cookies?.token}`,
        },
      });

      const data = await response.json();
      console.log(data);
      if (!data.success) {
        throw new Error(data.message);
      }
      setBillData(data);
    } catch (err) {
      toast({
        position: "top",
        status: "error",
        description: err.message,
      });
    } finally {
      setFetchingBill(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBillData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };


  const editBillHandler = async (e) => {
    e.preventDefault();
    try {
      setUpdatingBill(true);
      const baseUrl = process.env.REACT_APP_BACKEND_URL;
      const updatedBillData = { ...billData, bill_id:billId };
      const response = await fetch(`${baseUrl}bill/edit`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookies?.token}`,
        },
        body: JSON.stringify(updatedBillData),
      });

      const data = await response.json();
      
      
      if (!data.success) {
        throw new Error(data.message);
      }

      toast({
        status: "success",
        position: "top",
        description: data.message,
      });

      navigate("/dashboard/all-bill");
    } catch (err) {
      toast({
        position: "top",
        status: "error",
        description: err.message,
      });
    } finally {
      setUpdatingBill(false);
    }
  };

  useEffect(() => {
    if (location?.state?.billId) {
      setBillId(location?.state?.billId);
      fetchBillDetails(location?.state?.billId);
    }
  }, []);

  return (
    <div className="py-5 px-5 h-[inherit] overflow-auto">
      <h1 className="text-2xl md:text-4xl mt-5 mb-6 font-bold flex gap-2">
        <IoMdArrowRoundBack onClick={() => navigate("/dashboard/all-bill")} />
        Edit Bill
      </h1>

      {fetchingBill && <Loader />}

      {!fetchingBill && (
        <form onSubmit={editBillHandler}>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 mb-1 mt-2">
            <FormControl isRequired>
              <FormLabel>Bill No.</FormLabel>
              <Input
                type="text"
                name="bill_no"
                value={billData?.bill_no || ""}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Challan No.</FormLabel>
              <Input
                type="text"
                name="challan_no"
                value={billData?.challan_no || ""}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Bilty No.</FormLabel>
              <Input
                type="number"
                name="bilty_no"
                value={billData?.bilty_no || ""}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>From</FormLabel>
              <Input
                type="text"
                name="from"
                value={billData?.from || ""}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>To</FormLabel>
              <Input
                type="text"
                name="to"
                value={billData?.to || ""}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Lorry No.</FormLabel>
              <Input
                type="text"
                name="truck_no"
                value={billData?.truck_no || ""}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Description</FormLabel>
              <Textarea
                name="description"
                value={billData?.description || ""}
                onChange={handleChange}
              />
            </FormControl>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 mb-1 mt-2">
            <FormControl>
              <FormLabel>Date</FormLabel>
              <Input
                type="date"
                name="date"
                value={
                  billData?.date
                    ? new Date(billData.date).toISOString().split("T")[0]
                    : ""
                }
                onChange={handleChange}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Weight (In Tonne)</FormLabel>
              <Input
                type="text"
                name="weight"
                value={billData?.weight || ""}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Billed To</FormLabel>
              <Textarea
                name="consignee_name_address"
                value={billData?.consignee_name_address || ""}
                onChange={handleChange}
              />
            </FormControl>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 mb-1 mt-2">
            <FormControl isRequired>
              <FormLabel>Price Type</FormLabel>
              <select
                className="border rounded-md px-2 py-[9px] w-[18.5rem]"
                name="price_type"
                value={billData?.price_type || ""}
                onChange={handleChange}
              >
                <option>Fixed</option>
                <option>Per Unit</option>
              </select>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Price</FormLabel>
              <Input
                type="number"
                name="total"
                value={billData?.total || ""}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Advance</FormLabel>
              <Input
                type="number"
                name="advance"
                value={billData?.advance || ""}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel>GST No.</FormLabel>
              <Input
                type="text"
                name="gst_no"
                value={billData?.gst_no || ""}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel>St. Ch.</FormLabel>
              <Input
                type="number"
                name="st_ch"
                value={billData?.st_ch}
                onChange={handleChange
                }
              />
            </FormControl>
            <FormControl>
              <FormLabel>Del. Ch.</FormLabel>
              <Input
                type="number"
                name="del_ch"
                value={billData?.del_ch}
                onChange={handleChange
                }
              />
            </FormControl>
          </div>

          <div className="mt-5 mb-3">
            <Button
              isLoading={updatingBill}
              type="submit"
              width="100%"
              rightIcon={<LiaFileInvoiceSolid size={25} />}
              colorScheme="blue"
            >
              Edit Bill
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};

export default EditBill;
