import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  VStack,
  HStack,
  Text,
  Input,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  useToast,
  Badge,
} from "@chakra-ui/react";
import { MdModeEdit } from "react-icons/md";
import { GiReceiveMoney } from "react-icons/gi";
import AddPayment from "./AddPayment";
import { useCookies } from "react-cookie";
import axios from "axios";

const Payment = () => {
  const [data, setData] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [paymentOption, setPaymentOption] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedPayment, setSelectedPayment] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [cookies] = useCookies();
  const toast = useToast();


  const fetchPaymentData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}payment/`, {
        headers: {
          Authorization: `Bearer ${cookies?.token}`,
        },
      });
      setData(response?.data?.data || []);
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to fetch payment data.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };


  const filteredData = data.filter((row) => {
    let isValid = true;

    // Filter by name
    if (searchName && !row.partyId?.name.toLowerCase().includes(searchName.toLowerCase())) {
      isValid = false;
    }

    // Filter by payment type (Debit/Credit)
    if (paymentOption === "Debit" && row.paymentType !== "debit") {
      isValid = false;
    }
    if (paymentOption === "Credit" && row.paymentType !== "credit") {
      isValid = false;
    }

    // Filter by date
    if (selectedDate && new Date(row.date).toLocaleDateString() !== new Date(selectedDate).toLocaleDateString()) {
      isValid = false;
    }

    return isValid;
  });


  const calculateSubtotal = (type) => {
    return filteredData.reduce((total, item) => {
      if (type === "debit" && item.paymentType === "debit") {
        return total + item.amount;
      } else if (type === "credit" && item.paymentType === "credit") {
        return total + item.amount;
      }
      return total;
    }, 0);
  };


  const calculateProfitLoss = () => {
    return calculateSubtotal("debit") - calculateSubtotal("credit");
  };


  useEffect(() => {
    fetchPaymentData();
  }, [cookies?.token]);

  const handleEdit = (data)=>{
    setSelectedPayment(data);
    onOpen();

  }

  return (
    <VStack spacing={4} align="stretch" className="w-full overflow-hidden p-6">
      <HStack className="flex items-center justify-between">
        <Text className="text-2xl p-3 font-bold">Payments</Text>
        <Button leftIcon={<GiReceiveMoney size={25} />} colorScheme="blue" onClick={onOpen}>
          Add Payment
        </Button>
      </HStack>

      {/* Filters */}
      <HStack spacing={4}>
        <Input
          type="text"
          placeholder="Search by Name..."
          value={searchName}
          onChange={(e) => setSearchName(e.target.value)}
        />
        <Select
          value={paymentOption}
          onChange={(e) => setPaymentOption(e.target.value)}
          placeholder="Payment Option"
        >
          <option value="Debit">Debit</option>
          <option value="Credit">Credit</option>
        </Select>
        <Input
          type="date"
          value={selectedDate}
          onChange={(e) => setSelectedDate(e.target.value)}
        />
      </HStack>

      {/* Scrollable Table */}
      <div style={{ width: "100%", overflowX: "auto", marginTop: "20px" }}>
        <Table variant="simple" size="sm" style={{ minWidth: "900px" }}>
          <Thead>
            <Tr className="bg-teal-600 p-2">
              <Th>
                <Text className="text-white py-2 "> S.No.</Text>
              </Th>
              <Th>
                <Text className="text-white py-2 "> Date</Text>
              </Th>
              <Th>
                <Text className="text-white py-2 "> Name </Text>
              </Th>
              <Th>
                <Text className="text-white py-2 "> Debit</Text>
              </Th>
              <Th>
                <Text className="text-white py-2 "> Credit</Text>
              </Th>
              <Th>
                <Text className="text-white py-2 "> Remarks</Text>
              </Th>
              <Th>
                <Text className="text-white py-2 "> Actions</Text>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredData.map((row, index) => (
              <Tr key={row._id}>
                <Td>{index + 1}</Td>
                <Td>{new Date(row.date).toLocaleDateString()}</Td>
                <Td>{row.partyId?.name}</Td>
                <Td>{row.paymentType === "debit" ? row.amount : "-"}</Td>
                <Td>{row.paymentType === "credit" ? row.amount : "-"}</Td>
                <Td>{row.remarks}</Td>
                <Td>
                  <HStack spacing={2}>
                    <Button colorScheme="orange" size="sm"  onClick={()=> handleEdit(row)}>
                    <MdModeEdit />
                    </Button>
                  </HStack>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </div>

      {/* Subtotals and Profit/Loss */}
      <HStack justify="space-between" w="full" mt={4}>
        <VStack align="stretch">
          <Text fontWeight="bold">
            Subtotal of Debit: <span className="text-green-700">{calculateSubtotal("debit")}</span>
          </Text>
          <Text fontWeight="bold">
            Subtotal of Credit: <span className="text-red-700">{calculateSubtotal("credit")}</span>
          </Text>
          <Badge
            fontWeight="bold"
            background={calculateProfitLoss() >= 0 ? "green.200" : "red.200"}
            className="px-2  text-center text-lg"
          >
            {calculateProfitLoss() >= 0 ? "Profit" : "Loss"}: {calculateProfitLoss()}
          </Badge>
        </VStack>
      </HStack>

        {/* Modal for Adding/Editing Payment */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{selectedPayment ? "Edit Payment" : "Add Payment"}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AddPayment selectedPayment={selectedPayment} onClose={onClose} refreshData = {fetchPaymentData}/>
          </ModalBody>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default Payment;
