import {
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import axios from "axios";
import { useCookies } from "react-cookie";

const CreateParty = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [cookies] = useCookies();
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [gstNo, setGstNo] = useState("");


  const CreatePartyHandler = async (e) => {
    e.preventDefault();

    if (!name) {
      toast({
        title: "Error",
        description: "Please enter the party name.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}party/add-new`,
        { name, address, gstNo },
        {
          headers: {
            Authorization: `Bearer ${cookies?.token}`,
          },
        }
      );

      toast({
        title: "Party Created",
        description: `${ response?.message ? response?.message :  'The party has been created successfully.'}`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      
      setName("");
      setAddress("");
      setGstNo("");
      
    } catch (error) {
      console.log(error);

      toast({
        title: "Error",
        description: "Something went wrong while creating the party.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <div className="p-6 w-full mx-auto h-full bg-white rounded-lg shadow-lg">
      <h1 className="text-4xl mt-5 mb-10 font-bold flex gap-2">
        <IoMdArrowRoundBack onClick={() => navigate("/dashboard/")} />
       Add New Party
      </h1>
      <form onSubmit={CreatePartyHandler}>
        <FormControl isRequired mb={4}>
          <FormLabel htmlFor="name">Party Name</FormLabel>
          <Input
            id="name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter the party name"
          />
        </FormControl>

        <FormControl mb={4}>
          <FormLabel htmlFor="address">Address (Optional)</FormLabel>
          <Textarea
            id="address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            placeholder="Enter the party address"
          />
        </FormControl>

        <FormControl mb={4}>
          <FormLabel htmlFor="gstNo">GST No. (Optional)</FormLabel>
          <Input
            id="gstNo"
            type="text"
            value={gstNo}
            onChange={(e) => setGstNo(e.target.value)}
            placeholder="Enter the GST No."
          />
        </FormControl>

        <Button colorScheme="blue" type="submit" width="full">
          Add Party
        </Button>
      </form>
    </div>
  );
};

export default CreateParty;
