import { CiReceipt } from "react-icons/ci";
import { TbReceiptRupee } from "react-icons/tb";
import { LiaFileInvoiceSolid } from "react-icons/lia";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { MdPayments } from "react-icons/md";
import { Text } from "@chakra-ui/react";
import { FaUsers } from "react-icons/fa";
import { LuLayoutDashboard } from "react-icons/lu";

const Navigation = () => {
  const {isSuper} = useSelector(state => state.auth);

  return (
    <ul className="list-none text-xl space-y-4 px-3 py-3 h-[inherit] rounded ">
      <li className="border-b py-4">
      <Link
        to=""
        className="flex items-center gap-x-1 px-3 py-2  rounded-md hover:bg-[#3182ce] hover:text-white hover:scale-105 ease-in-out duration-300 cursor-pointer font-light"
        style={{ boxShadow: "0 0 10px 5px #f3f3f3" }}
      >
        <div>
          <LuLayoutDashboard  size={25} />
        </div>
        <span>Dashboard</span>
      </Link>
      </li>

      <li className="border-b py-4">
      <Link
        to="all-bilty"
        className="flex items-center gap-x-1 px-3 py-2 rounded-md hover:bg-[#3182ce] hover:text-white hover:scale-105 ease-in-out duration-300 cursor-pointer font-light"
        style={{ boxShadow: "0 0 10px 5px #f3f3f3" }}
      >
        <div>
          <CiReceipt size={25} />
        </div>
        <span>All Bilty</span>
      </Link>
      </li>

      <li className="border-b py-4">
      <Link
        to="all-challan"
        className="flex items-center gap-x-1 px-3 py-2 rounded-md hover:bg-[#3182ce] hover:text-white hover:scale-105 ease-in-out duration-300 cursor-pointer font-light"
        style={{ boxShadow: "0 0 10px 5px #f3f3f3" }}
      >
        <div>
          <TbReceiptRupee size={25} />
        </div>
        <span>All Challan</span>
      </Link>
      </li>

      <li className="border-b py-4">
      {isSuper && <Link
        to="all-bill"
        className="flex items-center gap-x-1 px-3 py-2 rounded-md hover:bg-[#3182ce] hover:text-white hover:scale-105 ease-in-out duration-300 cursor-pointer font-light"
        style={{ boxShadow: "0 0 10px 5px #f3f3f3" }}
      >
        <div>
          <LiaFileInvoiceSolid size={25} />
        </div>
        <span>All Bill</span>
      </Link>}
      </li>

      <li className="border-b py-4">
      {isSuper && <Link
        to="payment"
        className="flex items-center gap-x-1 px-3 py-2 rounded-md hover:bg-[#3182ce] hover:text-white hover:scale-105 ease-in-out duration-300 cursor-pointer font-light"
        style={{ boxShadow: "0 0 10px 5px #f3f3f3" }}
      >
        <div>
          <MdPayments  size={25} />
        </div>
        <span> All Payment</span>
      </Link>}
      </li>
      <li className="border-b py-4">
      {isSuper && <Link
        to="party"
        className="flex items-center gap-x-1 px-3 py-2 rounded-md hover:bg-[#3182ce] hover:text-white hover:scale-105 ease-in-out duration-300 cursor-pointer font-light"
        style={{ boxShadow: "0 0 10px 5px #f3f3f3" }}
      >
        <div>
          <FaUsers size={25} />
        </div>
        <span> All Party</span>
      </Link>}
      </li>
    </ul>
  );
};

export default Navigation;
