import React from "react";
import { CiReceipt } from "react-icons/ci";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import moment from "moment";
import Loader from "../components/Loader";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useCookies } from "react-cookie";

const BiltyDetails = () => {
  const toast = useToast();
  const location = useLocation();
  const [biltyId, setBiltyId] = useState();
  const [date, setDate] = useState(new Date().toISOString().substring(0, 10));
  const [branch, setBranch] = useState("");
  // const [driverName, setDriverName] = useState("");
  const [truckNo, setTruckNo] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [consigneeNameAddress, setConsigneeNameAddress] = useState("");
  const [consignerNameAddress, setConsignerNameAddress] = useState("");
  const [quantity, setQuantity] = useState();
  const [description, setDescription] = useState("");
  const [invoiceNo, setInvoiceNo] = useState("");
  const [actualWeight, setActualWeight] = useState("");
  const [chargedWeight, setChargedWeight] = useState("");
  const [fright, setFright] = useState("");
  const [hamali, setHamali] = useState("");
  const [surCh, setSurCh] = useState("");
  const [stCh, setStCh] = useState("");
  const [aoc, setAoc] = useState("");
  const [total, setTotal] = useState("");

  const [fetchingBilty, setFetchingBilty] = useState(false);

  const navigate = useNavigate();
  const [cookies] = useCookies();

  const fetchBiltyDetails = async (biltyId) => {
    try {
      setFetchingBilty(true);
      const baseUrl = process.env.REACT_APP_BACKEND_URL;
      const response = await fetch(baseUrl + `bilty/get?_id=${biltyId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookies?.token}`,
        },
      });
      const data = await response.json();
      if (!data.success) {
        throw new Error(data.message);
      }
      setDate(new Date(data?.date).toISOString().substring(0, 10));
      setBranch(data?.branch || "N/A");
      setFrom(data?.from || "N/A");
      setTo(data?.to || "N/A");
      // setDriverName(data?.driver_name || "N/A");
      setTruckNo(data?.truck_no || "N/A");
      setConsignerNameAddress(data?.consigner_name_address || "N/A");
      setConsigneeNameAddress(data?.consignee_name_address || "N/A");
      setQuantity(data?.quantity || "N/A");
      setDescription(data?.description || "N/A");
      setInvoiceNo(data?.invoice_no || "N/A");
      setActualWeight(data?.actual_weight || "N/A");
      setChargedWeight(data?.charged_weight || "N/A");
      setFright(data?.fright || "N/A");
      setHamali(data?.hamali || "N/A");
      setSurCh(data?.sur_ch || "N/A");
      setStCh(data?.st_ch || "N/A");
      setAoc(data?.aoc || "N/A");
      setTotal(data?.total || "N/A");
    } catch (error) {
      toast({
        status: "error",
        description: error.message,
        position: "top",
      });
    } finally {
      setFetchingBilty(false);
    }
  };

  useEffect(() => {
    if (location?.state?.biltyId) {
      setBiltyId(location?.state?.biltyId);
      fetchBiltyDetails(location?.state?.biltyId);
    }
  }, []);

  return (
    <div className="py-6 px-6 h-full overflow-auto bg-gray-50 min-h-screen">
      {/* Header Section */}
      <div className="flex items-center gap-3">
        <IoMdArrowRoundBack
          className="text-3xl cursor-pointer text-gray-700 hover:text-gray-900"
          onClick={() => navigate("/dashboard/")}
        />
        <h1 className="text-3xl md:text-4xl font-bold text-gray-800">
          Bilty Details
        </h1>
      </div>

      {fetchingBilty ? (
        <Loader />
      ) : (
        <div className="mt-6 bg-white shadow-lg rounded-xl p-6">
          {/* First Grid Section */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            <InfoCard label="Date" value={moment(date).format("DD-MM-YYYY")} />
            <InfoCard label="Branch" value={branch} />
            <InfoCard label="Truck No." value={truckNo} />
          </div>

          {/* Second Grid Section */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
            <InfoCard label="From" value={from} />
            <InfoCard label="To" value={to} />
          </div>

          {/* Consigner & Consignee */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
            <InfoCard
              label="Consigner Name and Address"
              value={consigneeNameAddress}
            />
            <InfoCard
              label="Consignee Name and Address"
              value={consigneeNameAddress}
            />
          </div>

          {/* Quantity, Description & Invoice */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6">
            <InfoCard label="Quantity" value={quantity} />
            <InfoCard label="Description" value={description} />
            <InfoCard label="Invoice No." value={invoiceNo} />
          </div>

          {/* Weight Section */}
          <div className="mt-8">
            <h2 className="text-xl font-semibold text-gray-700">
              Weight (In Tonne)
            </h2>
            <div className="grid grid-cols-2 gap-6 mt-2">
              <InfoCard label="Actual Weight" value={actualWeight} />
              <InfoCard label="Charged Weight" value={chargedWeight} />
            </div>
          </div>

          {/* Payment Section */}
          <div className="mt-10">
            <h2 className="text-xl font-semibold text-gray-700">
              Amount Breakdown
            </h2>
            <div className="bg-gray-100 rounded-lg p-5 mt-4">
              <PaymentRow label="Freight" value={fright} />
              <PaymentRow label="Hamali" value={hamali} />
              <PaymentRow label="Sur. Ch." value={surCh} />
              <PaymentRow label="St. Ch." value={stCh} />
              <PaymentRow label="A.O.C." value={aoc} />
              <div className="border-t border-gray-300 my-3"></div>
              <PaymentRow label="Total" value={total} isBold={true} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

// Reusable Component for Info Cards
const InfoCard = ({ label, value }) => (
  <div className="bg-white shadow-md rounded-lg p-4">
    <p className="text-gray-500 text-sm font-medium">{label}</p>
    <p className="text-gray-800 text-lg font-semibold">{value}</p>
  </div>
);

// Reusable Component for Payment Rows
const PaymentRow = ({ label, value, isBold }) => (
  <div className="flex justify-between items-center py-2">
    <p
      className={`text-sm ${
        isBold ? "font-bold text-lg" : "font-medium text-gray-600"
      }`}
    >
      {label}
    </p>
    <p
      className={`text-sm ${
        isBold ? "font-bold text-lg" : "font-medium text-gray-700"
      }`}
    >
      ₹{value}
    </p>
  </div>
);

export default BiltyDetails;
